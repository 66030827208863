@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700');

.selected-tree{
    background: green !important;
    color: #fff;
}
.tree-view{
    position: absolute;

left: 0px;

width: 85%;

top: 0;

background: #fff;
height:100%;
text-align: left !important;
color: #aaa !important;
border:0;
background-color: #fff !important;
} 
.tree-view:hover{
    box-shadow: 0 0 0 !important;
    color:#333 !important;
}

