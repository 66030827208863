@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700);
.selected-tree{
    background: green !important;
    color: #fff;
}
.tree-view{
    position: absolute;

left: 0px;

width: 85%;

top: 0;

background: #fff;
height:100%;
text-align: left !important;
color: #aaa !important;
border:0;
background-color: #fff !important;
} 
.tree-view:hover{
    box-shadow: 0 0 0 !important;
    color:#333 !important;
}


.sidebar .navigation ul li a,
.sidebar .navigation ul li button.btn-nothing, .hover-name, .shape .right .text, .shape-name .right .heading .designation, .shape-name.lg .right .text, .shape-name.xs .left, .shape-name.xs .right .heading, .manager .content .text, .tabs-container .right ul li .condition-container p.label, .folder-container .btn, .folder-container .path .btn-nothing span, .folder-container .folder-list .row .folder .count, .folder-container .folder-list .row .folder-info .folder-heading .right .text, .clickable-box .float, .profile-card .details .label, .profile-card .details1 .label, .table-cab p, .form-group.custom .form-control:not(:placeholder-shown) + label, .form-group.custom .form-control:focus + label, .form-group.custom.labelup label, .form-group .help-block, .form-content input::placeholder, .form-group.custom .form-control:-moz-read-only + label, .form-group.custom .form-control[readonly] + label, .muted-p, .unmuted-p {
  font-size: 0.8571rem;
}

.adminmenu .admin-nav, .adminmenu ul .heading a, .dropdown button, .dropdown .dropdown-menu .dropdown-item, .shape-name.lg .left, .manager .content .heading, .audit-step .s1 span, .folder-container .folder-list .row .folder-info .folder-heading .right .heading, .clickable-box .heading, .profile-card .details .value, .profile-card .edit-details, .profile-card .details1 .value, .box1 .heading span, .table-container form .search-custom .form-control, .table-container .table.table-striped, .table-container .table-footer .pagination li a, .form-group.custom, .form-group.custom label, .checkbox-container, .radio-container, .inner-header .shape-header .right .text, .modal-content .modal-last .btn-badge {
  font-size: 1rem;
}

.tabs-container .heading .sub-heading, .inner-header .shape-header .right .heading {
  font-size: 1.143rem;
}

.btn.btn-action i, .steps .step, .manager .settings, .folder-container .btn i, .box1 .heading, .inner-header .shape-header .left, .fixed-pop .facilities i {
  font-size: 1.286rem;
}

.sidebar .logo .bars, .add-minus::after, .search-custom span {
  font-size: 1.429rem;
}

.edit-dropdown .cancel, .clickable-box .left-icon .first, .clickable-box .left-icon .second, .box1 .left-icon i, .fixed-pop .inner-div .right {
  font-size: 1.767rem;
}

.dropdown.more .dropdown-toggle .icon, .select-div .abs .minus .icon {
  font-size: 2.2rem;
}

.dropdown button, .shape-name.xs .right .heading, .clickable-box .left-icon, .box1 .heading span, .box1 .left-icon, .table-container .table.table-striped td, .form-group.custom .form-control, .form-content input::-webkit-input-placeholder {
  font-weight: 400;
}

.dropdown button, .shape-name.xs .right .heading, .clickable-box .left-icon, .box1 .heading span, .box1 .left-icon, .table-container .table.table-striped td, .form-group.custom .form-control, .form-content input::-ms-input-placeholder {
  font-weight: 400;
}

.dropdown button, .shape-name.xs .right .heading, .clickable-box .left-icon, .box1 .heading span, .box1 .left-icon, .table-container .table.table-striped td, .form-group.custom .form-control, .form-content input::placeholder {
  font-weight: 400;
}

.sidebar .navigation ul li a,
.sidebar .navigation ul li button.btn-nothing, .sidebar.is--extended .navigation ul li a .hidden,
.sidebar.is--extended .navigation ul li button.btn-nothing .hidden, .adminmenu .admin-nav, .add-button, .add-minus::after, .steps .step, .shape-name .right .heading .designation, .shape-name.lg .right .heading, .audit-step .s1 span, .folder-container .folder-list .row .folder .name, .folder-container .folder-list .row .folder-info .empty, .folder-container .folder-list .row .folder-info .folder-heading .right .heading, .admin-tabs .nav.nav-tabs li a,
.admin-tabs .nav.nav-tabs li button, .clickable-box .heading, .profile-card .edit-details, .box1 .heading, .table-container .table.table-striped, .table-container .table.table-striped th, .custom-table thead th, .form-content input, .upload-input, .inner-header .shape-header .left, .inner-header .shape-header .right .heading, .modal-content .text-bold, .center-heading h4, .create-report .heading h5, .create-report .card .heading, .report__head {
  font-weight: 600;
}

.shape .right .heading, .tabs-container .right .bottom-abs ul li .condition-container p, .menu-items li .table-movable::after, .table-container .table-footer .pagination li.active a, .inner-header .header-right .btn-nothing, .white-card .sub-heading, .expense-tabs li.active a {
  font-weight: 700;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.sidebar {
  z-index: 99;
}

.adminmenu {
  z-index: 98;
}

.add-button {
  z-index: 10;
}

.page-header {
  z-index: 95;
}

.toaster {
  z-index: 1051;
}

.steps {
  z-index: 1;
}

.steps::after {
  z-index: -1;
}

html, body {
  font-size: 13px;
  background-color: #E5F1FF;
}

body {
  font-family: Open Sans;
  color: #8b8c99;
  letter-spacing: 0.3px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a:hover, a, a:focus {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #E5F1FF;
}

::-webkit-scrollbar-thumb {
  background: #9dbfff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.login {
  height: 100vh;
}

.login .container-fluid {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
}

.login .container-fluid .login__half {
  width: 50%;
  height: 100%;
  float: left;
}

.login .container-fluid .login__half:first-child {
  background: #fff url("/assets/images/login-background-pattran.jpg") no-repeat center center/cover;
}

.login .container-fluid .login__half:first-child .d-table {
  padding: 0 100px;
}

.login .container-fluid .login__half:first-child .d-table.d-table__middle > div {
  position: relative;
  text-align: left;
}

.login .container-fluid .login__half:first-child .d-table.d-table__middle > div .logo {
  margin-bottom: 15px;
}

.login .container-fluid .login__half:first-child .d-table.d-table__middle > div .logo img {
  width: 120px;
}

.login .container-fluid .login__half:first-child .d-table.d-table__middle > div .login-abs {
  position: absolute;
  top: auto;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.login .container-fluid .login__half:last-child {
  background: #377DFF url("/assets/images/login-background.jpg") no-repeat center center/cover;
  color: #fff;
}

.login .container-fluid .login__half:last-child h1 {
  font-size: 60px;
}

@media screen and (max-width: 991px) {
  .login .container-fluid .login__half:first-child .d-table {
    padding: 0 25px;
  }
}

@media screen and (max-width: 767px) {
  .login .container-fluid .login__half {
    width: 100%;
    float: none;
  }
  .login .container-fluid .login__half:last-child {
    display: none;
  }
}

.sidebar {
  position: fixed;
  top: 0px;
  right: auto;
  bottom: auto;
  left: 0px;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  width: 63px;
  height: 100vh;
  transition: all 0.3s ease;
  background-color: #334681;
}

.sidebar .hidden {
  display: none;
  opacity: 0;
}

.sidebar .logo {
  width: 63px;
  height: 48px;
  transition: all 0.4s ease;
  background-color: #377DFF;
  padding: 6px 10px 10px 10px;
  overflow: hidden;
  text-align: center;
}

.sidebar .logo img.logo-img, .sidebar .logo img.logo-img1 {
  width: auto;
  height: 36px;
}

.sidebar .logo img.logo-img {
  display: none;
}

.sidebar .logo .bars {
  width: 100%;
  height: 40px;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease;
  border-top: 1px solid #3f569e;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
  color: #a7a9c0;
}

.sidebar .logo .bars i {
  color: #a7a9c0;
  line-height: 1.2;
}

.sidebar .logo .bars:hover .hover-name {
  display: block;
  top: 8px;
}

.sidebar .navigation {
  margin: 10px 0;
}

.sidebar .navigation ul li a,
.sidebar .navigation ul li button.btn-nothing {
  position: relative;
  display: block;
  border: 0;
  width: 100%;
  text-align: left;
  color: #8b8c99;
  background-color: #334681;
  border-left: 2px solid transparent;
  padding: 14px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .navigation ul li a:hover,
.sidebar .navigation ul li button.btn-nothing:hover {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.sidebar .navigation ul li a:hover i,
.sidebar .navigation ul li button.btn-nothing:hover i {
  color: #fff;
}

.sidebar .navigation ul li a:hover .hover-name,
.sidebar .navigation ul li button.btn-nothing:hover .hover-name {
  display: block;
}

.sidebar .navigation ul li a.active,
.sidebar .navigation ul li button.btn-nothing.active {
  border-left: 2px solid #fff;
  background-color: transparent;
}

.sidebar .navigation ul li a.active i,
.sidebar .navigation ul li a.active span,
.sidebar .navigation ul li button.btn-nothing.active i,
.sidebar .navigation ul li button.btn-nothing.active span {
  color: #fff;
}

.sidebar .navigation ul li a div,
.sidebar .navigation ul li button.btn-nothing div {
  width: 26px;
  height: 26px;
  overflow: hidden;
  margin-left: 7px;
}

.sidebar .navigation ul li a div i,
.sidebar .navigation ul li button.btn-nothing div i {
  display: inline-block;
  color: #a7a9c0;
  line-height: 26px;
  text-align: center;
  font-size: 24px;
  vertical-align: middle;
}

.sidebar.is--extended {
  width: 280px;
  background-color: #fff;
}

.sidebar.is--extended .hidden {
  display: inline-block;
  opacity: 1;
}

.sidebar.is--extended .logo {
  width: 100%;
  padding-left: 25px;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f2;
  text-align: left;
}

.sidebar.is--extended .logo img.logo-img {
  margin-right: 10px;
  display: inline-block;
}

.sidebar.is--extended .logo img.logo-img1 {
  display: none;
}

.sidebar.is--extended .logo .bars {
  display: none;
}

.sidebar.is--extended .navigation ul li .hover-name {
  display: none !important;
}

.sidebar.is--extended .navigation ul li a,
.sidebar.is--extended .navigation ul li button.btn-nothing {
  background-color: #fff;
  color: #8b8c99;
  padding: 7px 15px 7px 21px;
  border-left-width: 4px;
}

.sidebar.is--extended .navigation ul li a div,
.sidebar.is--extended .navigation ul li button.btn-nothing div {
  vertical-align: middle;
  display: inline-block;
  margin-left: 0;
}

.sidebar.is--extended .navigation ul li a div i,
.sidebar.is--extended .navigation ul li button.btn-nothing div i {
  color: #8b8c99;
}

.sidebar.is--extended .navigation ul li a .hidden,
.sidebar.is--extended .navigation ul li button.btn-nothing .hidden {
  margin: 0 16px;
  vertical-align: middle;
  font-size: 14px;
  line-height: 24px;
  color: #8b8c99;
}

.sidebar.is--extended .navigation ul li a:hover,
.sidebar.is--extended .navigation ul li button.btn-nothing:hover {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.sidebar.is--extended .navigation ul li a:hover i,
.sidebar.is--extended .navigation ul li a:hover .hidden,
.sidebar.is--extended .navigation ul li button.btn-nothing:hover i,
.sidebar.is--extended .navigation ul li button.btn-nothing:hover .hidden {
  color: #377DFF;
}

.sidebar.is--extended .navigation ul li a:hover .hover-name,
.sidebar.is--extended .navigation ul li button.btn-nothing:hover .hover-name {
  display: block;
}

.sidebar.is--extended .navigation ul li a.active,
.sidebar.is--extended .navigation ul li button.btn-nothing.active {
  border-left: 4px solid #377DFF;
  color: #377DFF;
}

.sidebar.is--extended .navigation ul li a.active i,
.sidebar.is--extended .navigation ul li a.active span,
.sidebar.is--extended .navigation ul li a.active .hidden,
.sidebar.is--extended .navigation ul li button.btn-nothing.active i,
.sidebar.is--extended .navigation ul li button.btn-nothing.active span,
.sidebar.is--extended .navigation ul li button.btn-nothing.active .hidden {
  color: #377DFF;
}

.adminmenu {
  position: fixed;
  top: 48px;
  right: auto;
  bottom: 0px;
  left: 63px;
  width: 200px;
  height: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.adminmenu .admin-nav {
  padding: 8px 16px;
  line-height: 18px;
  letter-spacing: 0.1em;
  width: 100%;
  text-align: left;
  text-transform: none;
}

.adminmenu .search-custom .form-control {
  border: 0;
  border-top: 1px solid #f1f1f2;
  border-bottom: 1px solid #f1f1f2;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
}

.adminmenu .search-custom .form-control:hover, .adminmenu .search-custom .form-control:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  border-top: 1px solid #9dbfff;
  border-bottom: 1px solid #9dbfff;
}

.adminmenu ul.nav-overflow {
  height: 80%;
  height: -o-calc(100% - 48px);
  height: calc(100% - 48px);
  overflow-y: auto;
  overflow-x: hidden;
}

.adminmenu ul .heading a {
  position: relative;
  display: block;
  color: #8b8c99;
  padding: 8px 16px;
  margin: 0;
  line-height: 18px;
  width: 100%;
  text-align: left;
  text-transform: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  transition: all 0.3s ease-in;
}

.adminmenu ul .heading a i {
  position: absolute;
  top: 8px;
  right: 12px;
  bottom: auto;
  left: auto;
}

.adminmenu ul .heading a.active, .adminmenu ul .heading a:hover, .adminmenu ul .heading a:focus {
  background-color: #6a9eff;
  color: #fff;
}

button {
  outline: 0;
}

button:focus, button:hover, button:active {
  outline: 0;
}

.btn {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 0px 12px;
  line-height: 32px;
  letter-spacing: 0.1em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  color: #377DFF;
  background: #fff;
  margin: 0;
  border: 1px solid #fff;
}

.btn:focus, .btn:hover, .btn:active {
  background-color: #E5F1FF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid #E5F1FF;
}

.btn.btn-nothing {
  padding: 0;
  background: transparent;
  border: 0px;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  text-transform: none;
  color: #8b8c99;
  line-height: 1rem;
}

.btn.btn-nothing:focus, .btn.btn-nothing:hover, .btn.btn-nothing:active {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.btn.btn-no-radius {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
}

.btn.btn-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.btn.btn-upload {
  padding: 3px 10px;
}

.btn.btn-upload i.icon {
  font-size: 22px;
  line-height: 22px;
}

.btn.btn-action {
  background-color: #34495e;
  border: 1px solid #34495e;
  color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  position: relative;
}

.btn:disabled {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  background-color: transparent;
  color: #8b8c99;
  border: 1px solid #ededed;
  opacity: 0.5;
}

.btn.btn-arrow {
  background-color: #6B6B6B;
  color: #fff;
}

.btn.btn-arrow i.icon {
  font-size: 22px;
}

.btn.btn-arrow:focus, .btn.btn-arrow:hover, .btn.btn-arrow:active {
  background: #666666;
  border-color: #666666;
}

.btn.btn-arrow.primary {
  background-color: #377DFF;
}

.btn-group-margin .btn {
  margin-right: 10px;
  width: auto;
}

.btn-group-margin .dropdown {
  display: inline-block;
  margin-right: 10px;
}

.btn-group-margin .dropdown button {
  width: auto;
  margin-right: 0px;
}

.btn-group-margin .form-group {
  display: inline-block;
  margin-right: 10px;
  border: 0;
}

.btn-group-margin .form-group .form-control {
  background-color: #F7F7F7;
  height: 34px;
  border: 0;
}

.btn-group-margin .form-group .form-control:focus, .btn-group-margin .form-group .form-control:hover, .btn-group-margin .form-group .form-control:active {
  background-color: #F7F7F7;
}

.btn-group-margin .form-group .icon-custom {
  position: absolute;
  top: 5px;
  right: 10px;
  bottom: auto;
  left: auto;
  color: #C4C4C4;
  cursor: pointer;
}

.btn-group-margin :last-child {
  margin-right: 0;
}

.btn-group-margin label.radio-container {
  display: inline-block;
  margin-right: 20px;
}

.add-button {
  position: fixed;
  top: auto;
  right: 50px;
  bottom: 50px;
  left: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  border: 0;
  background: #377DFF;
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}

.add-button:focus, .add-button:hover, .add-button:active {
  color: #fff;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  background: #2d76ff;
  border-color: #2d76ff;
}

/* dropdown */
.dropdown {
  position: relative;
  vertical-align: top;
  height: 40px;
  line-height: 40px;
}

.dropdown button {
  width: 100%;
  height: 100%;
  text-align: left;
  padding-right: 35px;
  color: #8b8c99;
  border: 1px solid #C4C4C4;
  background-color: transparent;
  text-transform: capitalize;
  padding: 0 15px;
  margin: 0;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  vertical-align: top;
}

.dropdown button.btn-border-basic {
  color: #8b8c99;
}

.dropdown button:hover, .dropdown button:active, .dropdown button:focus {
  background-color: transparent;
  border: 1px solid #C4C4C4;
}

.dropdown button.dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 46%;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdown button:disabled {
  opacity: 0.3;
  background-color: #F7F7F7;
}

.dropdown .dropdown-menu {
  min-width: 180px;
  width: 100%;
  border: 0;
  margin: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-transform: translate3d(0px, 41px, 0px) !important;
          transform: translate3d(0px, 41px, 0px) !important;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown .dropdown-menu .dropdown-item {
  cursor: pointer;
  border: 0;
  white-space: normal !important;
  line-height: 1.4 !important;
  margin: 0;
  padding: 10px 15px;
  word-break: break-all;
}

.dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:focus {
  background-color: #E5F1FF;
  color: #377DFF;
}

.dropdown.more {
  height: 20px;
}

.dropdown.more .dropdown-toggle {
  background: #fff;
  border: 0px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
  -webkit-transform-origin: 8px 10px;
          transform-origin: 8px 10px;
  padding: 0px 7px;
}

.dropdown.more .dropdown-toggle .icon {
  line-height: 1;
}

.dropdown.more .dropdown-toggle:active, .dropdown.more .dropdown-toggle:focus, .dropdown.more .dropdown-toggle:hover {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  color: #377DFF;
}

.dropdown.more .dropdown-toggle::after {
  border: 0;
}

.dropdown.more .dropdown-menu {
  min-width: 200px;
  left: auto !important;
  right: 0 !important;
  top: 100% !important;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
          transform: translate3d(0px, 0px, 0px) !important;
}

.dropdown.custom-dropdown {
  display: inline-block;
}

.dropdown.custom-dropdown .dropdown-toggle {
  background: #6B6B6B;
  color: #fff;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -o-border-radius: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #6B6B6B;
}

.dropdown.custom-dropdown .dropdown-toggle::after {
  border: 0;
}

.dropdown.custom-dropdown .dropdown-menu {
  min-width: 230px;
}

.dropdown.custom-dropdown .dropdown-menu[x-placement="bottom-start"] {
  margin-top: 9px;
  border-top: 3px solid #377DFF;
}

.dropdown.custom-dropdown .dropdown-menu[x-placement="bottom-start"]::after {
  content: "";
  position: absolute;
  top: -10px;
  right: auto;
  bottom: auto;
  left: 20px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #377DFF;
}

.dropdown.custom-dropdown .dropdown-menu[x-placement="top-start"] {
  margin-bottom: 9px;
  border-bottom: 3px solid #377DFF;
}

.dropdown.custom-dropdown .dropdown-menu[x-placement="top-start"]::after {
  content: "";
  position: absolute;
  top: auto;
  right: auto;
  bottom: -10px;
  left: 20px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #377DFF;
}

.dropdown.custom-dropdown .dropdown-menu .dropdown-item {
  padding: 0.75rem 1.5rem;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #badc58;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0 10px;
}

.add-minus {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  background: #fff;
  vertical-align: middle;
  margin-right: 10px;
  position: relative;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

.add-minus::after {
  content: "+";
  position: absolute;
  top: 0px;
  right: auto;
  bottom: auto;
  left: 0px;
  line-height: 20px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.add-minus.minus::after {
  content: "-";
}

.px-padding {
  padding-left: 25px;
  padding-right: 25px;
}

.py-padding {
  padding-top: 25px;
  padding-bottom: 25px;
}

.p-padding {
  padding: 25px;
}

.light-shadow {
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
}

.bg-light-blue {
  background-color: #E5F1FF;
}

.wrapper {
  margin-left: 63px;
  position: relative;
}

.page-header {
  position: fixed;
  top: 0px;
  right: auto;
  bottom: auto;
  left: auto;
  background: #fff;
  width: 100%;
  height: auto;
  padding: 0px 16px;
  width: 95%;
  width: -o-calc(100% - 63px);
  width: calc(100% - 63px);
  height: 49px;
  border-bottom: 1px solid #f1f1f2;
  box-shadow: 0 1px 4px rgba(150, 150, 150, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(150, 150, 150, 0.1);
  -webkit-box-shadow: 0 1px 4px rgba(150, 150, 150, 0.1);
  -o-box-shadow: 0 1px 4px rgba(150, 150, 150, 0.1);
}

.page-header .heading {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 0;
}

.page-header .right-items {
  float: right;
}

.page-header .right-items li {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.page-header .right-items li .logo-holder {
  padding: 0 10px;
}

.page-header .right-items li .user-image {
  cursor: pointer;
  width: 48px;
  vertical-align: middle;
  height: 48px;
  text-align: center;
  line-height: 48px;
}

.page-header .right-items li .user-image .material-icons {
  vertical-align: middle;
  font-size: 32px;
  line-height: 48px;
}

.page-header .right-items li .user-image .left.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 60%;
  height: 60%;
  vertical-align: middle;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-block;
}

.page-header .right-items li .notification-bell,
.page-header .right-items li .help-image,
.page-header .right-items li .more-image {
  cursor: pointer;
  text-align: center;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.page-header .right-items li .notification-bell i.material-icons,
.page-header .right-items li .help-image i.material-icons,
.page-header .right-items li .more-image i.material-icons {
  font-size: 24px;
  line-height: 32px;
  vertical-align: middle;
}

.page-header .right-items li .user-image:hover,
.page-header .right-items li .notification-bell:hover,
.page-header .right-items li .help-image:hover,
.page-header .right-items li .more-image:hover {
  background-color: #F7F7F7;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
}

.page-header .right-items li .user-image:hover ~ .hover-name,
.page-header .right-items li .notification-bell:hover ~ .hover-name,
.page-header .right-items li .help-image:hover ~ .hover-name,
.page-header .right-items li .more-image:hover ~ .hover-name {
  display: block;
}

.page-header .right-items li .user-image i {
  color: #377DFF;
}

.page-header .right-items li .delegates-popup {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  min-width: 300px;
  top: 54px;
  z-index: 20;
  right: 0;
}

.page-header .right-items li .delegates-popup::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 14px;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.page-header .right-items li .delegates-popup .user-info {
  padding: 20px;
  border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
}

.page-header .right-items li .delegates-popup .act-as1 {
  padding: 3px 20px;
}

.page-header .right-items li .delegates-popup .act-as1 p {
  margin: 0;
}

.page-header .right-items li .delegates-popup .delegetes-list {
  max-height: 150px;
  border-top: 1px solid #f1f1f2;
  border-bottom: 1px solid #f1f1f2;
  overflow-x: hidden;
  overflow-y: auto;
}

.page-header .right-items li .delegates-popup .delegetes-list .shape-name {
  margin-bottom: 0;
  background-color: #fff;
  padding: 12px 20px;
  cursor: pointer;
}

.page-header .right-items li .delegates-popup .delegetes-list .shape-name:nth-child(odd) {
  background-color: #F7F7F7;
}

.page-header .right-items li .delegates-popup .btns {
  padding: 20px;
}

.page-header .right-items li .notification-msg {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 480px;
  top: 54px;
  right: -16px;
  z-index: 20;
}

.page-header .right-items li .notification-msg::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 30px;
  bottom: auto;
  left: auto;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.page-header .right-items li .notification-msg .notification_head {
  padding: 15px;
}

.page-header .right-items li .notification-msg .notification-body {
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid #f1f1f2;
  border-bottom: 1px solid #f1f1f2;
}

.page-header .right-items li .notification-msg .notification-body .shape-name {
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f2;
  margin-bottom: 0;
}

.page-header .right-items li .notification-msg .notification-body .shape-name.unread {
  background-color: #F7F7F7;
}

.page-header .right-items li .notification-msg .notification-body .shape-name .right {
  margin-left: 45px;
}

.page-header .right-items li .notification-msg .notification-foot a {
  width: 100%;
  display: block;
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
}

.page-header .right-items li .help-msg {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  min-width: 170px;
  top: 54px;
  right: -16px;
  z-index: 20;
}

.page-header .right-items li .help-msg::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 30px;
  bottom: auto;
  left: auto;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.page-header .right-items li .help-msg ul li {
  display: block;
  float: none;
  width: 100%;
}

.page-header .right-items li .help-msg ul li a {
  display: block;
  padding: 8px 15px;
  font-size: 13px;
  color: #334681;
}

.page-header .right-items li .help-msg ul li a i {
  font-size: 18px;
  color: #334681;
  margin-right: 6px;
}

.page-header .right-items li .help-msg ul li:hover {
  background-color: #F7F7F7;
}

.page-header .p-10 {
  padding: 10px 0px;
}

.inner-wrapper {
  margin-left: 200px;
  position: relative;
}

.inner-wrapper .page-header {
  padding: 0 16px;
  left: 63px;
  width: 95%;
  width: -o-calc(100% - 63px);
  width: calc(100% - 63px);
}

.form-holder {
  background-color: #fff;
  padding: 25px;
}

.form-holder .table-container {
  margin: 0 -25px;
}

.form-holder .table-container .table-footer {
  padding-bottom: 0;
}

.page-content {
  padding-top: 50px;
}

.sub-heading {
  color: #334681;
  margin-bottom: 15px;
}

.steps {
  text-align: center;
  padding: 25px 15px;
  margin-bottom: 15px;
  position: relative;
}

.steps::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 62%;
  height: 3px;
  margin: auto;
  background: #377DFF;
}

.steps .step {
  display: inline-block;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 48px;
  background: #fff;
  border: 2px solid #377DFF;
  color: #377DFF;
  text-align: center;
  margin: 0 8%;
}

.steps .step.active {
  background: #377DFF;
  color: #fff;
}

.hover-v {
  position: relative;
}

.hover-v:hover .hover-name {
  display: block;
}

.hover-name {
  display: none;
  position: absolute;
  left: 100%;
  top: 16px;
  line-height: 18px;
  background-color: #6B6B6B;
  padding: 3px 8px;
  color: #fff;
  white-space: nowrap;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  z-index: 9999;
}

.hover-name::after {
  content: "";
  position: absolute;
  left: -6px;
  top: 5px;
  border-right: 6px solid #6B6B6B;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.hover-name.right-top {
  top: 0px;
  left: 107%;
  left: -o-calc(100% + 5px);
  left: calc(100% + 5px);
}

.hover-name.right-top.more {
  top: 5px;
}

.hover-name.bottom {
  top: 107%;
  top: -o-calc(100% + 5px);
  top: calc(100% + 5px);
  right: auto;
  left: -8px;
}

.hover-name.bottom::after {
  border-bottom: 6px solid #6B6B6B;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  top: -12px;
  left: 18px;
}

.hover-name.bottom.more {
  left: 0;
}

.shape {
  position: relative;
}

.shape .left {
  position: absolute;
  left: 0;
  top: 0;
}

.shape .left .CircularProgressbar {
  width: 40px;
}

.shape .right {
  margin-left: 50px;
}

.shape .right .heading {
  margin: 0 0 5px;
}

.shape .right .text {
  margin: 0;
  color: #8b8c99;
}

/* name initials */
.shape-name {
  color: #8b8c99;
  position: relative;
  margin-bottom: 5px;
}

.shape-name .left {
  float: left;
  background: #377DFF;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  color: #fff;
  font-weight: 600;
}

.shape-name .left.blue {
  background-color: #313896;
}

.shape-name .left.purple {
  background-color: #802392;
}

.shape-name .left.grey {
  background-color: #303037;
}

.shape-name .left.l-grey {
  background-color: #a7a9c0;
  color: #fff;
}

.shape-name .left.l-grey-icon {
  line-height: 1;
}

.shape-name .left.l-grey-icon i {
  line-height: 32px;
  font-size: 20px;
}

.shape-name .left.orange {
  background-color: #c48268;
}

.shape-name .left.img {
  background-color: #C4C4C4;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.shape-name .left.icon i {
  font-size: 32px;
  vertical-align: top;
}

.shape-name .left.user-icon {
  background-color: transparent;
  padding: 0;
  height: auto;
  line-height: 1rem;
}

.shape-name .left.user-icon i {
  color: #8b8c99;
  font-size: 18px;
}

.shape-name .right {
  margin-left: 40px;
}

.shape-name .right .text {
  margin-bottom: 0;
  line-height: 14px;
  text-transform: capitalize;
}

.shape-name .right .heading {
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 18px;
  font-size: 14px;
  line-height: 20px;
}

.shape-name .right .heading .designation {
  border: 1px solid #79a8ff;
  background: #79a8ff;
  color: #fff;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  margin-left: 5px;
  padding: 0px 5px;
}

.shape-name.lg .left {
  width: 40px;
  height: 40px;
  padding: 11px 0;
}

.shape-name.lg .left.icon {
  padding: 6px 0;
  font-size: 20px;
}

.shape-name.lg .right {
  margin-left: 55px;
}

.shape-name.lg .right .heading {
  margin: 0 0 3px;
  padding: 2px 0 0 0;
}

.shape-name.lg .right .text {
  margin: 0 0 2px 0;
  color: #8b8c99;
}

.shape-name.xs .left {
  width: 25px;
  height: 25px;
  padding: 4px 0;
}

.shape-name.xs .right {
  margin-left: 30px;
}

.shape-name.xs .right .heading {
  margin: 0;
  padding: 6px 0 0 0;
}

.shape-name.margin-bottom {
  margin-bottom: 20px;
}

.shape-name.bg-light-blue {
  background: #fff;
}

.shape-name.bg-light-blue .left {
  background: #84afff;
}

.shape-group .shape-name {
  display: inline-block;
  margin-right: 15px;
}

.edit-dropdown {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: none;
}

.edit-dropdown input {
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  background-color: #E5F1FF;
  padding: 5px 10px 5px 50px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.edit-dropdown .cancel {
  position: absolute;
  top: 6px;
  right: auto;
  bottom: auto;
  left: 10px;
  width: 25px;
  height: 25px;
  display: inline-block;
  color: #fff;
  background-color: #377DFF;
  cursor: pointer;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
}

.edit-dropdown .dropdown-list {
  background: #fff;
  display: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.edit-dropdown .dropdown-list li {
  color: #8b8c99;
  padding: 8px 10px;
  border-bottom: 1px solid #f1f1f2;
}

.edit-dropdown.show {
  display: block;
}

.edit-dropdown input:focus ~ .dropdown-list {
  display: block;
}

.manager {
  position: relative;
  border: 1px solid #fff;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  -o-border-radius: 30px;
  padding: 5px;
}

.manager .img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #C4C4C4;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 5px;
  right: auto;
  bottom: auto;
  left: 7px;
}

.manager .content {
  margin-left: 45px;
}

.manager .content .text {
  margin: 0;
}

.manager .content .heading {
  color: #334681;
  margin: 0;
}

.manager .settings {
  position: absolute;
  top: 13px;
  right: 7px;
  bottom: auto;
  left: auto;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  display: none;
  cursor: pointer;
}

.manager:hover {
  border: 1px dashed #C4C4C4;
  background-color: #F7F7F7;
}

.manager:hover .settings {
  display: block;
}

.delegate {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f1f2;
}

.delegate .names {
  float: left;
  max-width: 35%;
  width: 100%;
  border-right: 1px solid #f1f1f2;
}

.delegate .names .search-custom .form-control {
  border-radius: 0px;
  background-color: #F7F7F7;
}

.delegate .names ul {
  padding: 10px 0;
}

.delegate .names ul li {
  padding: 5px 15px;
  cursor: pointer;
}

.delegate .names ul li.active {
  background-color: #E5F1FF;
}

.delegate .detail {
  float: left;
  max-width: 65%;
  width: 100%;
  padding: 10px 15px 0;
}

.delegate .detail .all-detail {
  padding: 15px 15px 0;
  background-color: #F7F7F7;
  border-radius: 5px;
}

.delegate .detail .all-detail form {
  margin-top: 15px;
}

.delegate .scroll {
  overflow-y: auto;
  height: 180px;
  padding-bottom: 15px;
}

.tabs-container {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f1f2;
  height: 500px;
}

.tabs-container .heading {
  padding: 12px 15px;
}

.tabs-container .heading .sub-heading {
  margin: 0;
  color: #334681;
}

.tabs-container .scroll {
  height: 90%;
  height: -o-calc(100% - 43px);
  height: calc(100% - 43px);
  overflow-y: auto;
  padding-bottom: 15px;
}

.tabs-container .left {
  float: left;
  max-width: 40%;
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;
  z-index: 11;
  box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.1);
}

.tabs-container .left .select-all {
  background-color: #6B6B6B;
  width: 100%;
  padding: 8px 15px;
  text-align: left;
  border: 0;
  color: #fff;
}

.tabs-container .left ul li .btn-nothing {
  padding: 10px 15px;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  display: block;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  text-align: left;
  text-transform: capitalize;
}

.tabs-container .left ul li .btn-nothing:hover, .tabs-container .left ul li .btn-nothing:focus, .tabs-container .left ul li .btn-nothing:active {
  box-shadow: 0px 0px 0px #fff;
  -moz-box-shadow: 0px 0px 0px #fff;
  -webkit-box-shadow: 0px 0px 0px #fff;
  -o-box-shadow: 0px 0px 0px #fff;
}

.tabs-container .left ul li:nth-child(odd) .btn-nothing {
  background-color: #F7F7F7;
}

.tabs-container .left ul li.selected .btn-nothing {
  background-color: #377DFF;
  color: #fff;
}

.tabs-container .right {
  float: left;
  max-width: 60%;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

.tabs-container .right .scroll {
  height: 80%;
  height: -o-calc(100% - 113px);
  height: calc(100% - 113px);
  padding-bottom: 0;
}

.tabs-container .right .scroll .scroll-content {
  padding: 15px;
}

.tabs-container .right .heading .btn,
.tabs-container .right .heading label {
  float: right;
  margin-top: 5px;
}

.tabs-container .right ul li {
  padding: 10px 15px;
  background-color: #fff;
  border-top: 1px solid #f1f1f2;
}

.tabs-container .right ul li:nth-child(odd) {
  background-color: #F7F7F7;
}

.tabs-container .right ul li .condition-container {
  padding: 0 80px 0 50px;
  position: relative;
}

.tabs-container .right ul li .condition-container .left-toggle {
  position: absolute;
  top: 10px;
  right: auto;
  bottom: auto;
  left: 0px;
}

.tabs-container .right ul li .condition-container .right-edit {
  position: absolute;
  top: 7px;
  right: 0px;
  bottom: auto;
  left: auto;
}

.tabs-container .right ul li .condition-container p {
  margin: 0;
}

.tabs-container .right ul li .condition-container p.label {
  text-transform: uppercase;
}

.tabs-container .right .bottom-abs {
  position: absolute;
  top: auto;
  right: 0px;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.tabs-container .right .bottom-abs ul li {
  border: 0;
}

.tabs-container .right .bottom-abs ul li .condition-container {
  padding: 0 80px 0 50px;
  position: relative;
}

.tabs-container .right .bottom-abs ul li .condition-container .left-toggle {
  position: absolute;
  top: 2px;
  right: auto;
  bottom: auto;
  left: 0px;
}

.tabs-container .right .bottom-abs ul li .condition-container .right-edit {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: auto;
  left: auto;
}

.tabs-container .right .bottom-abs ul li .condition-container p {
  padding: 3px 0;
  color: #377DFF;
}

.tabs-container .right_trip {
  background-color: #F7F7F7;
  padding: 0;
}

.tabs-container .right_trip .label {
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
}

.tabs-container .right_trip .label-heading {
  color: #334681;
}

.tabs-container .validity {
  background-color: #C4C4C4;
  padding: 8px 15px;
}

.tabs-container .validity .left-text {
  padding: 4px 0 2px;
}

.tabs-container .validity .left-text p {
  display: inline-block;
  margin: 0;
  padding-right: 30px;
  font-size: 12px;
}

.tabs-container .validity .left-text p b {
  color: #334681;
}

.tabs-container .validity .btn {
  float: right;
}

.discount-flag {
  border: 1px solid #C4C4C4;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 4px;
}

.discount-flag p {
  margin-top: -20px;
  background-color: #fff;
  width: 129px;
  text-align: center;
  border-radius: 20px;
}

.dragndrop {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f1f2;
}

.dragndrop .top {
  padding: 15px;
}

.dragndrop .top .form-group {
  margin: 0;
}

.dragndrop .dnd {
  border-top: 1px solid #C4C4C4;
  height: 380px;
}

.dragndrop .dnd .search-custom .form-control {
  border-radius: 0px;
  border: 0;
  padding: 5px 35px 5px 15px;
  height: auto;
}

.dragndrop .dnd .scroll {
  height: 90%;
  height: -o-calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  padding-bottom: 15px;
}

.dragndrop .dnd ul li {
  padding: 10px 15px;
  background-color: #fff;
  cursor: pointer;
}

.dragndrop .dnd ul li:nth-child(odd) {
  background-color: #E5F1FF;
}

.dragndrop .dnd ul li.selected {
  background-color: #377DFF;
  color: #fff;
}

.dragndrop .dnd .left {
  float: left;
  max-width: 47%;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.dragndrop .dnd .left .select-all {
  background-color: #6B6B6B;
  width: 100%;
  padding: 8px 15px;
  text-align: left;
  border: 0;
  color: #fff;
}

.dragndrop .dnd .left .scroll {
  height: 80%;
  height: -o-calc(100% - 68px);
  height: calc(100% - 68px);
}

.dragndrop .dnd .right {
  float: left;
  max-width: 47%;
  width: 100%;
  height: 100%;
}

.dragndrop .dnd .center {
  height: 100%;
  display: table;
  float: left;
  max-width: 6%;
  width: 100%;
  text-align: center;
  background-color: #F7F7F7;
  border-right: 1px solid #C4C4C4;
  border-left: 1px solid #C4C4C4;
}

.dragndrop .dnd .center .mid {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.dragndrop .dnd .center .mid button {
  margin-bottom: 20px;
  width: 40px;
  height: 40px;
  padding: 5px 15px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
}

.dragndrop .dnd .center .mid button i {
  font-size: 30px;
  line-height: 20px;
}

.audit-step .s1 {
  padding: 7px 40px;
  display: inline-block;
  border: 1px solid #f1f1f2;
  color: #8b8c99;
  margin-bottom: 20px;
}

.audit-step .s1:first-child {
  border-radius: 5px 0 0 5px;
}

.audit-step .s1:last-child {
  border-radius: 0 5px 5px 0;
}

.audit-step .s1 span {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-color: #6B6B6B;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  text-align: center;
  line-height: 25px;
  margin-right: 10px;
  color: #fff;
}

.audit-step .s1.active {
  border: 1px solid #377DFF;
  background-color: #377DFF;
  color: #fff;
}

.audit-step .s1.active span {
  background-color: #fff;
  color: #377DFF;
}

.select-div {
  padding: 20px 50px;
  position: relative;
}

.select-div .abs {
  position: absolute;
  top: 25px;
  right: auto;
  bottom: auto;
  left: 15px;
}

.select-div .abs .minus .icon {
  line-height: 1;
  color: #ff7979;
}

.select-div.footer {
  padding: 20px 50px;
}

.and-or {
  background-color: #F7F7F7;
  padding: 20px 50px;
}

.and-or .radio-container {
  display: inline-block;
  margin-right: 50px;
  margin-bottom: 0;
}

.menu-items li .table-movable {
  background-color: #334681;
  display: inline-block;
  min-width: 280px;
  padding: 10px 40px 10px 25px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
}

.menu-items li .table-movable.no-child {
  background-color: #546dbc;
}

.menu-items li .table-movable.selected {
  background-color: #badc58;
}

.menu-items li .table-movable.selected::after {
  color: #66801b;
}

.menu-items li .table-movable.selectedSub {
  background-color: #ffa643;
}

.menu-items li .table-movable.selectedSub::after {
  color: #66801b;
}

.menu-items li .table-movable.selectedFun {
  background-color: #34495e;
  color: #8b8c99;
}

.menu-items li .table-movable.selectedFun::after {
  color: #080b13;
}

.menu-items li .table-movable.selectedPage {
  background-color: #377DFF;
}

.menu-items li .table-movable.selectedPage::after {
  color: #66801b;
}

.menu-items li .table-movable::after {
  content: "";
  position: absolute;
  top: 7px;
  right: auto;
  bottom: auto;
  left: 6px;
  content: "||";
  color: #e2e3eb;
  cursor: pointer;
}

.menu-items li .table-movable .heading {
  margin: 0;
  color: #fff;
}

.menu-items li .table-movable .right-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: auto;
  left: auto;
  background-color: transparent;
}

.menu-items li .table-movable .right-icon .dropdown.more .dropdown-toggle {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background-color: transparent;
  padding: 2px 10px;
}

.menu-items li .table-movable .right-icon .dropdown.more .dropdown-toggle i {
  color: #fff;
}

.menu-items li ul {
  margin-left: 30px;
  border-left: 1px dashed;
  padding: 3px;
  border-radius: 6px;
}

.menu-items li .btn-border-success {
  min-width: 280px;
  margin-bottom: 15px;
}

.block-overflow {
  width: 100%;
  overflow: auto;
}

.file-tree {
  height: 350px;
  margin: 15px 0;
  overflow-y: auto;
}

.file-tree.no-scroll {
  height: auto;
  overflow: hidden;
}

.file-tree ul {
  overflow: hidden;
}

.file-tree ul li {
  margin: 5px 0px;
}

.file-tree ul li ul {
  margin: 10px 0px 10px 25px;
}

.file-tree ul li .btn-nothing img {
  width: auto;
  height: 20px;
  margin-top: -2px;
}

.file-tree ul li .btn-nothing .text {
  padding: 0px 20px;
  color: #334681;
}

.file-tree ul li .btn-nothing::before {
  content: "-";
  text-align: center;
  width: 24px;
  height: 24px;
  margin: 0px 20px 0 0;
  color: #334681;
  border: 1px solid #f1f1f2;
  display: inline-block;
}

.file-tree ul li .btn-nothing.collapsed::before {
  content: "+";
}

.file-tree ul li .btn-nothing.no-child::before {
  content: "";
  border: 0;
}

.file-tree ul li .right-buttons {
  float: right;
}

.f-button {
  padding: 30px 15px;
}

.check-item {
  padding: 3px 15px;
}

.folder-container {
  border: 1px solid #f1f1f2;
  background-color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  overflow: hidden;
}

.folder-container .dropdown-menu {
  -webkit-transform: translate3d(0px, 34px, 0px) !important;
          transform: translate3d(0px, 34px, 0px) !important;
}

.folder-container .btn {
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 30px;
}

.folder-container .btn.btn-action {
  background-color: #34495e;
  border: 1px solid #34495e;
  color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  position: relative;
}

.folder-container .btn.btn-action:disabled {
  background-color: #d6dbdf;
  border: 1px solid #d6dbdf;
  opacity: 1;
}

.folder-container .btn.btn-action.btn-copy.active {
  padding-right: 0;
}

.folder-container .btn.btn-action.btn-copy i {
  float: left;
}

.folder-container .btn.btn-action.btn-copy .new-btn {
  background-color: #badc58;
  display: inline-block;
  padding: 0 10px;
  margin-left: 10px;
  border-radius: 0 3px 3px 0;
}

.folder-container .btn.active {
  background-color: #34495e;
  border: 1px solid #34495e;
  color: #fff;
}

.folder-container .heading {
  padding: 15px;
  position: relative;
}

.folder-container .heading .dropdown {
  float: right;
  margin: 0 0 0 2%;
  width: 46%;
  vertical-align: top;
  height: 32px;
  line-height: 32px;
}

.folder-container .heading .dropdown button {
  vertical-align: top;
}

.folder-container .heading .search-custom {
  float: right;
  margin: 0 0 0 2%;
  width: 46%;
  height: 32px;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.folder-container .heading .search-custom .form-control {
  border: 1px solid #C4C4C4;
  height: auto;
  padding: 5px 35px 4px 15px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
}

.folder-container .path {
  border-top: 1px solid #f1f1f2;
  border-bottom: 1px solid #f1f1f2;
  position: relative;
  min-height: 30px;
}

.folder-container .path .btn-nothing {
  padding: 0px 10px;
  height: 100%;
  height: 30px;
  line-height: 30px;
  margin: 0;
  border-right: 1px solid #f1f1f2;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  display: inline-block;
  vertical-align: top;
}

.folder-container .path .btn-nothing img {
  width: 12px;
  display: inline-block;
  margin: -3px 5px 0 0;
}

.folder-container .path .btn-nothing span {
  display: inline-block;
}

.folder-container .folder-list {
  min-height: 400px;
  position: relative;
}

.folder-container .folder-list .column-reset {
  height: 100%;
  position: relative;
  margin: 0 -30px 0 -15px;
  padding: 0 15px 15px;
}

.folder-container .folder-list .column-reset .buttons-set {
  position: absolute;
  top: auto;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  text-align: center;
  margin-left: 15px;
}

.folder-container .folder-list .column-reset .buttons-set button {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  margin: 0;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
}

.folder-container .folder-list .column-reset .buttons-set button:first-child {
  border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
}

.folder-container .folder-list .column-reset .buttons-set button:last-child {
  border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
}

.folder-container .folder-list .row {
  min-height: 400px;
}

.folder-container .folder-list .row .folder {
  height: 140px;
  vertical-align: top;
  display: inline-block;
  padding: 30px 10px 5px;
  border: 1px solid #f1f1f2;
  width: 22.5%;
  margin: 15px 0 0 15px;
  text-align: center;
  background-color: #fff;
}

.folder-container .folder-list .row .folder img {
  height: 40px;
}

.folder-container .folder-list .row .folder .name {
  text-align: center;
  margin: 8px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folder-container .folder-list .row .folder .count {
  margin: 0;
}

.folder-container .folder-list .row .folder.selected {
  background-color: #E5F1FF;
}

.folder-container .folder-list .row .folder-info {
  width: 100%;
  height: 100%;
  border-left: 1px solid #f1f1f2;
  background-color: #F7F7F7;
  display: table;
}

.folder-container .folder-list .row .folder-info .empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.folder-container .folder-list .row .folder-info .empty span {
  display: block;
  margin: 0 auto;
  background-color: #fff;
  color: #8b8c99;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.folder-container .folder-list .row .folder-info .folder-heading {
  position: relative;
  padding: 15px;
  border-bottom: 1px solid #f1f1f2;
  background-color: #fff;
}

.folder-container .folder-list .row .folder-info .folder-heading img {
  position: absolute;
  top: 15px;
  right: auto;
  bottom: auto;
  left: 15px;
  width: 40px;
}

.folder-container .folder-list .row .folder-info .folder-heading .right {
  margin-left: 50px;
}

.folder-container .folder-list .row .folder-info .folder-heading .right .heading {
  margin-bottom: 0px;
  padding: 0;
}

.folder-container .folder-list .row .folder-info .folder-heading .right .text {
  margin: 0;
  color: #8b8c99;
}

.folder-container .folder-list .row .folder-info .custom-dropdown .btn.btn-nothing {
  width: 100%;
  background-color: #fff;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  border-bottom: 1px solid #f1f1f2;
  margin: 0;
  text-align: left;
  padding: 0px 15px;
}

.folder-container .folder-list .row .folder-info .custom-dropdown .custom-dropdown-inner {
  padding: 15px;
}

.folder-container .folder-list .row .folder-info .custom-dropdown .custom-dropdown-inner.white {
  background: #fff;
}

.folder-container .folder-list .row .folder-info .custom-dropdown .custom-dropdown-inner .empty {
  text-align: center;
  display: block;
}

.folder-container .folder-list .row .folder-info .custom-dropdown .custom-dropdown-inner .folder-heading {
  border: 0;
  padding: 0;
  margin-bottom: 18px;
}

.folder-container .folder-list .row .folder-info .custom-dropdown .custom-dropdown-inner .folder-heading img {
  top: 0px;
  left: 0;
}

.add-new-popup {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
  position: absolute;
  top: 52px;
  right: auto;
  bottom: auto;
  left: 0px;
  min-width: 313px;
  z-index: 100;
}

.add-new-popup .box-head {
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #273747;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 8px 15px;
}

.add-new-popup .box-head h6 {
  margin: 5px 0;
}

.add-new-popup .box-head h6 img {
  width: auto;
  height: 18px;
  margin-right: 10px;
  vertical-align: top;
}

.add-new-popup .box-content {
  color: #fff;
  background-color: #34495e;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.add-new-popup .box-content h6 {
  text-transform: uppercase;
}

.add-new-popup .box-content .form-group {
  margin-bottom: 20px;
}

.add-new-popup::after {
  content: "";
  position: absolute;
  top: -7px;
  right: auto;
  bottom: auto;
  left: 28px;
  border-bottom: 7px solid #34495e;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.add-new-popup.plus::after {
  left: 93px;
}

.add-new-popup.edit::after {
  left: 141px;
}

.admin-tabs .nav.nav-tabs {
  position: relative;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  height: 50px;
  border-bottom: 0px;
  background-color: #fff;
}

.admin-tabs .nav.nav-tabs li a,
.admin-tabs .nav.nav-tabs li button {
  padding: 13px 25px;
  display: block;
  color: #8b8c99;
  border: 0;
  background-color: transparent;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

.admin-tabs .nav.nav-tabs li a.active,
.admin-tabs .nav.nav-tabs li button.active {
  border-bottom: 3px solid #377DFF;
}

.color-desp {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: #fff;
  border: 1px solid #F7F7F7;
  z-index: 100;
}

.color-desp p {
  margin-bottom: 8px;
}

.color-desp p:last-child {
  margin-bottom: 0;
}

.color-desp .color {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  background: #334681;
  vertical-align: middle;
  margin-right: 15px;
}

.color-desp p:nth-child(2) .color {
  background: #34495e;
}

.color-desp p:nth-child(3) .color {
  background: #34495e;
}

.color-desp p:nth-child(4) .color {
  background: #377DFF;
}

.clickable-box {
  position: relative;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  border: 1px solid #f1f1f2;
  margin-bottom: 25px;
  background: #fff;
  padding: 15px 15px 15px 60px;
  cursor: pointer;
}

.clickable-box p {
  margin: 0;
}

.clickable-box .heading {
  margin-bottom: 5px;
}

.clickable-box .left-icon {
  background: #E5F1FF;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 15px;
  right: auto;
  bottom: auto;
  left: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  text-align: center;
}

.clickable-box .left-icon .first {
  line-height: 40px;
}

.clickable-box .left-icon .second {
  display: none;
  line-height: 40px;
}

.clickable-box .float {
  float: right;
}

.clickable-box.active {
  border: 1px solid #badc58;
}

.clickable-box.active .heading {
  color: #badc58;
}

.clickable-box.active .left-icon {
  background-color: #badc58;
}

.clickable-box.active .left-icon .first {
  display: none;
}

.clickable-box.active .left-icon .second {
  display: inline-block;
}

.p-padding.fix-padding {
  padding: 20px;
}

.profile-card {
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  background-color: #fff;
}

.profile-card.grey {
  background-color: #F7F7F7;
}

.profile-card.fix-heigth {
  height: 90vh;
  overflow: scroll;
}

.profile-card .details {
  padding: 15px;
}

.profile-card .details .label {
  margin: 0 0 5px 0;
  text-transform: uppercase;
}

.profile-card .details .value {
  color: #334681;
  margin: 0 0 20px 0;
}

.profile-card .details > *:last-child {
  margin: 0;
}

.profile-card .edit-details {
  display: block;
  width: 100%;
  text-align: center;
  border: 0;
  background: #fff;
  border-top: 1px solid #f1f1f2;
  padding: 8px;
  color: #377DFF;
  text-transform: uppercase;
  cursor: pointer;
}

.profile-card .details1 {
  padding: 15px 0;
}

.profile-card .details1 .label {
  padding: 0 15px;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.profile-card .details1 .value {
  padding: 0 15px;
  margin: 0 0 10px;
  color: #334681;
}

.profile-card .details1 .value.border-b {
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f2;
}

.profile-card .details1 .value:last-child {
  border: 0;
  padding-bottom: 0;
}

.profile-card .details1 > *:last-child {
  margin: 0;
}

.box1 {
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  margin-bottom: 15px;
  background: #fff;
  padding: 15px 60px;
  position: relative;
}

.box1 .heading {
  margin-bottom: 2px;
}

.box1 .heading span {
  margin-left: 10px;
}

.box1 .left-icon {
  width: 40px;
  height: 40px;
  background: #E5F1FF;
  position: absolute;
  top: 15px;
  right: auto;
  bottom: auto;
  left: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  text-align: center;
}

.box1 .left-icon i {
  line-height: 40px;
}

.box1 .right-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: auto;
  left: auto;
}

.modal {
  background: rgba(134, 134, 134, 0.5);
}

.modal .modal-content {
  border: 0;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
}

.modal .modal-content .modal-title {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}

.modal .modal-content button.close {
  color: #334681;
  opacity: 1;
}

.d-table {
  width: 100%;
  height: 100%;
  display: table;
}

.d-table.d-table__middle > div {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.table-container {
  background-color: #fff;
  margin: 0 25px;
}

.table-container.min-height .table-responsive {
  padding-bottom: 230px;
}

.table-container .dropdown {
  height: 34px;
}

.table-container .dropdown button {
  background-color: #F7F7F7;
  border: 1px solid #F7F7F7;
  padding-right: 35px;
  margin-bottom: 0;
  vertical-align: top;
}

.table-container .dropdown .dropdown-menu .dropdown-item {
  width: 100%;
  background-color: transparent;
}

.table-container .dropdown .dropdown-menu .dropdown-item:focus, .table-container .dropdown .dropdown-menu .dropdown-item:hover, .table-container .dropdown .dropdown-menu .dropdown-item:active {
  background-color: #E5F1FF;
}

.table-container form {
  padding: 25px 25px 0;
  margin-bottom: 5px;
}

.table-container form .search-custom {
  margin-bottom: 10px;
  height: 32px;
  background-color: #F7F7F7;
  border: 1px solid #F7F7F7;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  border: 0;
}

.table-container form .search-custom .form-control {
  border: 0;
  background-color: transparent;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 6px 35px 6px 15px;
  height: 100%;
}

.table-container form .search-custom .form-control:hover {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
}

.table-container form .search-custom span {
  top: 0;
}

.table-container form .search-custom span i {
  font-size: 16px;
  line-height: 32px;
  vertical-align: top;
}

.table-container .table {
  vertical-align: middle;
}

.table-container .table .active {
  background-color: #377DFF;
  color: #fff;
}

.table-container .table tr {
  border-bottom: 1px solid #f1f1f2;
  background-color: #fff;
}

.table-container .table tr th:first-child,
.table-container .table tr td:first-child {
  padding-left: 25px;
}

.table-container .table tr th:last-child,
.table-container .table tr td:last-child {
  padding-right: 25px;
}

.table-container .table.table-striped {
  color: #8b8c99;
}

.table-container .table.table-striped tr {
  background-color: #fff;
}

.table-container .table.table-striped.table-selected tbody tr {
  background-color: #fff;
  cursor: pointer;
}

.table-container .table.table-striped.table-selected tbody tr:hover {
  background-color: #F7F7F7;
}

.table-container .table.table-striped.table-selected tbody tr.active-tr {
  color: #377DFF;
  background-color: #F7F7F7;
  cursor: pointer;
}

.table-container .table.table-striped th {
  border: 0;
  color: #334681;
  vertical-align: middle;
  text-transform: uppercase;
  cursor: pointer;
}

.table-container .table.table-striped th .table-h {
  position: relative;
  display: inline-block;
}

.table-container .table.table-striped th .table-h .table-sort {
  position: absolute;
  top: auto;
  right: -24px;
  bottom: auto;
  left: auto;
  display: none;
  width: 20px;
}

.table-container .table.table-striped th:hover .table-sort {
  display: inline-block;
}

.table-container .table.table-striped td {
  border: 0;
  vertical-align: middle;
}

.table-container .table.table-striped td p,
.table-container .table.table-striped td h1,
.table-container .table.table-striped td h2,
.table-container .table.table-striped td h3,
.table-container .table.table-striped td h4,
.table-container .table.table-striped td h5,
.table-container .table.table-striped td h6 {
  margin: 0;
}

.table-container .table.table-striped td .link {
  color: #377DFF;
}

.table-container .table-footer {
  padding: 10px 25px 25px;
}

.table-container .table-footer .page-count .text {
  display: inline-block;
  margin-top: 0;
  margin-right: 10px;
  line-height: 30px;
  vertical-align: top;
}

.table-container .table-footer .page-count .count {
  display: inline-block;
}

.table-container .table-footer .page-count .count button.btn {
  padding-right: 35px;
}

.table-container .table-footer .pagination {
  float: right;
}

.table-container .table-footer .pagination li a {
  display: inline-block;
  text-align: center;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  vertical-align: middle;
  background-color: #F7F7F7;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  margin-right: 10px;
  color: #a7a9c0;
}

.table-container .table-footer .pagination li a:focus, .table-container .table-footer .pagination li a:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  background: #f2f2f2;
  border-color: #f2f2f2;
}

.table-container .table-footer .pagination li.active a {
  background: #377DFF;
  color: #fff;
}

.table-container .table-footer .pagination li.active a:focus, .table-container .table-footer .pagination li.active a:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  background: #1e6cff;
  border-color: #1e6cff;
}

.table-container .table-footer .pagination li:last-child a {
  margin-right: 0;
}

.table-container .table-container {
  margin-left: -25px;
  margin-right: -25px;
  background-color: #F7F7F7;
}

.table-container .table-container form {
  padding: 5px 40px;
}

.table-container .table-container tr:nth-child(even) {
  background-color: #f4f4f4;
}

.table-container .table-container tr:nth-child(odd) {
  background-color: #F7F7F7;
}

.table-container .table-container tr th:first-child,
.table-container .table-container tr td:first-child {
  padding-left: 40px;
}

.table-container .table-container tr th:last-child,
.table-container .table-container tr td:last-child {
  padding-right: 40px;
}

.table-container .table-container .table.table-striped {
  margin: 0;
}

.table-container .table-container .table.table-striped tbody tr:last-child td {
  border-bottom: 0px;
}

.table-container .table-container .table.table-striped tbody tr:hover {
  background-color: #fff;
}

.table-container .table-container .table.table-striped.table-selected tbody tr:nth-child(even) {
  background-color: #f4f4f4;
}

.table-container .table-container .table.table-striped.table-selected tbody tr:nth-child(odd) {
  background-color: #F7F7F7;
}

.table-container .table-container .table.table-striped.table-selected tbody tr:hover {
  background-color: #fff;
}

.table-container .table-container .table.table-striped.table-selected tbody tr.active-tr {
  background-color: #fff;
}

.table-container .table-restricted {
  height: 350px;
  overflow: auto;
}

.table-container .setwidth {
  width: 300px;
}

.table.table-borderless th {
  text-transform: uppercase;
  color: #334681;
}

.custom-table thead th {
  border: 0;
  padding-left: 0;
  padding-right: 0;
}

.custom-table tbody td {
  border: 0;
  border-right: 1px solid #f1f1f2;
  padding: 15px;
}

.custom-table tbody td:first-child {
  border-left: 2px solid #f1f1f2;
}

.custom-table tbody tr:first-child td {
  padding: 0;
  border-top: 1px solid #f1f1f2;
}

.custom-table tbody tr:first-child td .search-custom {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  height: 45px;
  line-height: 45px;
}

.custom-table tbody tr:first-child td .search-custom .form-control {
  background-color: #F7F7F7;
  border: 0;
  padding-left: 15px;
}

.custom-table tfoot {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
}

.custom-table tfoot td {
  border: 1px solid #f1f1f2;
}

.custom-table tfoot td:first-child {
  border-left: 2px solid #f1f1f2;
}

.table-cab {
  text-align: center;
  margin: 0;
}

.table-cab p {
  padding-top: 5px;
  color: #334681;
}

.table-cab p.light {
  color: #a7a9c0;
}

.table-cab td {
  padding: 0;
  position: relative;
  height: 70px;
  border: 1px solid #F7F7F7;
  vertical-align: middle;
}

.table-cab td button {
  border: 0;
  width: 100%;
  margin: 0;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
}

.table-cab td button h6 {
  margin: 0px;
}

.table-cab td button.sold {
  background-color: #E5F1FF;
}

.table-cab td button.selected {
  background-color: #badc58;
  color: #fff;
}

.bg-table {
  background-color: #F7F7F7;
}

.search-custom {
  position: relative;
  height: 35px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.search-custom .form-control {
  padding-right: 30px;
  height: 100%;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
}

.search-custom .form-control::-webkit-input-placeholder {
  color: #a7a9c0;
}

.search-custom .form-control::-ms-input-placeholder {
  color: #a7a9c0;
}

.search-custom .form-control::placeholder {
  color: #a7a9c0;
}

.search-custom span {
  position: absolute;
  top: 1px;
  right: 10px;
  bottom: auto;
  left: auto;
  color: #C4C4C4;
  cursor: pointer;
}

.form-control {
  outline: 0;
  box-shadow: 0 0 0;
}

.form-control:focus, .form-control:active, .form-control:hover {
  outline: 0;
  box-shadow: 0 0 0;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-group.custom {
  border: 1px solid #C4C4C4;
  height: 40px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: #fff;
}

.form-group.custom.white {
  background: #fff;
}

.form-group.custom label {
  position: absolute;
  top: 9px;
  right: auto;
  bottom: auto;
  left: 10px;
  background: transparent;
  padding: 0 4px;
  transition: all 0.1s ease-in;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -o-border-radius: 25px;
  z-index: 0;
  text-transform: capitalize;
}

.form-group.custom .form-control {
  position: relative;
  border: 0px;
  padding: 0 15px;
  line-height: 40px;
  height: 40px;
  outline: 0;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  background-color: transparent;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  z-index: 1;
}

.form-group.custom .form-control:focus, .form-group.custom .form-control:active, .form-group.custom .form-control:hover {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  outline: 0;
}

.form-group.custom .form-control:not(:placeholder-shown) + label {
  top: -10px;
  z-index: 1;
  background-color: #fff;
}

.form-group.custom .form-control:focus + label {
  top: -10px;
  z-index: 1;
  background-color: #fff;
}

.form-group.custom:focus, .form-group.custom:hover, .form-group.custom:active {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #bfbfbf;
}

.form-group.custom .dropdown {
  height: 100%;
  width: 100%;
}

.form-group.custom .dropdown button {
  border: 0;
  height: 100%;
  width: 100%;
  line-height: 40px;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.form-group.custom .dropdown button:hover, .form-group.custom .dropdown button:active, .form-group.custom .dropdown button:focus {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.form-group.custom .dropdown:focus, .form-group.custom .dropdown:hover, .form-group.custom .dropdown:active {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.form-group.custom.labelup label {
  top: -10px;
  z-index: 1;
  background-color: #fff;
}

.form-group.custom.textarea {
  height: auto;
}

.form-group.custom.textarea textarea.form-control {
  height: auto;
}

.form-group.custom .react-datepicker-wrapper {
  width: 100%;
  display: block;
  height: 38px;
}

.form-group.custom .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
  display: block;
  height: 100%;
}

.form-group.custom .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  display: block;
  padding: 4px 15px;
  border: 0;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
}

.form-group.custom .react-datepicker-popper {
  z-index: 100;
}

.form-group.has-error {
  position: relative;
}

.form-group .help-block {
  color: #ff7979;
  line-height: 1rem;
}

/* toggle switch buttons */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 14px;
  margin: 0 10px 0 5px;
  vertical-align: middle;
}

.switch:last-child {
  margin: 0px;
}

.switch input {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.switch .slider {
  background-color: #C4C4C4;
  cursor: pointer;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  -o-border-radius: 50px;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  transition: all 0.2s ease-in;
}

.switch .slider::before {
  background-color: #F7F7F7;
  content: "";
  position: absolute;
  top: -3px;
  right: auto;
  bottom: auto;
  left: -3px;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
}

.switch input:checked + .slider {
  background-color: #b7d0ff;
}

.switch input:focus + .slider {
  box-shadow: 0px 0px 1px #377DFF;
  -moz-box-shadow: 0px 0px 1px #377DFF;
  -webkit-box-shadow: 0px 0px 1px #377DFF;
  -o-box-shadow: 0px 0px 1px #377DFF;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  transform: translateX(21px);
  background-color: #377DFF;
}

.switch.grey input:checked + .slider {
  background-color: #919191;
}

.switch.grey input:checked + .slider:before {
  background-color: #6B6B6B;
}

.switch ~ span {
  vertical-align: top;
  display: inline-block;
  width: 90%;
  width: -o-calc(100% - 55px);
  width: calc(100% - 55px);
}

/* checkbox buttons */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container.small .checkmark {
  width: 15px;
  height: 15px;
}

.checkbox-container.small .checkmark::after {
  width: 6px;
  height: 10px;
  left: 4px;
  top: 1px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0px;
  height: 0px;
}

.checkbox-container .checkmark {
  position: absolute;
  top: 0px;
  right: auto;
  bottom: auto;
  left: 0px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #377DFF;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
}

.checkbox-container .checkmark::after {
  display: none;
  content: "";
  position: absolute;
  top: 2px;
  right: auto;
  bottom: auto;
  left: 6px;
  width: 7px;
  height: 12px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #377DFF;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #377DFF;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-container .checkmark {
  position: absolute;
  top: 0px;
  right: auto;
  bottom: auto;
  left: 0px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  border: 2px solid #6B6B6B;
}

.radio-container .checkmark::after {
  display: none;
  content: "";
  position: absolute;
  top: 3px;
  right: auto;
  bottom: auto;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
  background: #377DFF;
}

.radio-container:hover .checkmark {
  border: 2px solid #377DFF;
}

.radio-container:hover input ~ .checkmark::after {
  display: block;
}

.radio-container input:checked ~ .checkmark {
  border: 2px solid #377DFF;
}

.radio-container input:checked ~ .checkmark:after {
  display: block;
}

.form-content {
  background: #fff;
  border: 2px solid #6B6B6B;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -o-border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;
  height: 40px;
}

.form-content:focus, .form-content:hover, .form-content:active {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #666666;
}

.form-content input::-webkit-input-placeholder {
  color: #8b8c99;
  opacity: 1;
  text-transform: capitalize;
}

.form-content input::-ms-input-placeholder {
  color: #8b8c99;
  opacity: 1;
  text-transform: capitalize;
}

.form-content input::placeholder {
  color: #8b8c99;
  opacity: 1;
  text-transform: capitalize;
}

.form-content input {
  width: 50%;
  height: 100%;
  float: left;
  border: 0;
  box-shadow: 0 0 0 #fff;
  -moz-box-shadow: 0 0 0 #fff;
  -webkit-box-shadow: 0 0 0 #fff;
  -o-box-shadow: 0 0 0 #fff;
  display: block;
  padding: 5px 15px;
  color: #334681;
}

.form-content span {
  background-color: #6B6B6B;
  width: 50%;
  height: 100%;
  float: left;
  color: #fff;
  display: block;
  padding: 0 10px;
  line-height: 36px;
  text-align: center;
}

.form-content.light {
  border: 1px solid #C4C4C4;
}

.form-content.light span {
  background-color: #F7F7F7;
  color: #8b8c99;
  border-left: 1px solid #C4C4C4;
}

.upload-input {
  position: relative;
  border: 1px dashed #C4C4C4;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  padding: 20px;
  color: #8b8c99;
  background: #fff;
}

.upload-input:focus, .upload-input:hover, .upload-input:active {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px dashed #bfbfbf;
}

.upload-input input {
  position: absolute;
  top: 0px;
  right: auto;
  bottom: auto;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.inner-header {
  padding: 20px 25px 0;
  text-transform: capitalize;
}

.inner-header.light-shadow {
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
}

.inner-header.pb-padding {
  padding-bottom: 25px;
}

.inner-header.grey {
  background-color: #f7f7f7;
}

.inner-header .options {
  text-align: right;
  padding-top: 10px;
}

.inner-header .shape-header {
  color: #8b8c99;
  position: relative;
}

.inner-header .shape-header .left {
  float: left;
  background: #6B6B6B;
  width: 38px;
  height: 40px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

.inner-header .shape-header .left.favicon {
  border: 1px solid #C4C4C4;
  background-color: transparent;
}

.inner-header .shape-header .left.favicon img {
  vertical-align: top;
}

.inner-header .shape-header .right {
  margin-left: 50px;
}

.inner-header .shape-header .right .heading {
  color: #334681;
  margin-bottom: 0px;
}

.inner-header .shape-header .right .text {
  margin: 0;
}

.inner-header .company-logo {
  height: 50px;
  width: auto;
}

.inner-header .company-logo img {
  height: 100%;
  width: auto;
}

.inner-header .header-right {
  float: right;
}

.inner-header .header-right .btn-nothing {
  color: #377DFF;
}

.booking-bg {
  background-color: #E5F1FF;
  height: 100%;
}

.booking-bg .my-card {
  background-color: #fff;
  margin-bottom: 20px;
}

.booking-bg .my-card .my-padding {
  padding: 15px;
}

.booking-bg .my-card .heading {
  vertical-align: middle;
}

.booking-bg .my-card .heading i {
  font-size: 25px;
  margin-right: 10px;
  vertical-align: middle;
}

.booking-bg .my-card .quality {
  padding: 15px;
}

.booking-bg .my-card .quality .transport-booking {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #f1f1f2;
  display: inline-block;
  text-align: center;
  width: 95px;
  height: 110px;
  margin: 10px 10px;
}

.booking-bg .my-card .quality .transport-booking i {
  font-size: 30px;
}

.booking-bg .my-card .quality .some-class p:last-child {
  color: #377DFF;
}

.booking-bg .my-card .quality .some-class p:last-child i {
  margin-right: 5px;
}

.booking-bg .my-card .quality .some-class p:last-child.details {
  cursor: pointer;
}

.booking-bg .my-card .quality .flash i {
  font-size: 35px;
}

.booking-bg .my-card table tbody td {
  color: #377DFF;
}

.booking-choice {
  border-radius: 25px;
  margin-top: 10px;
  height: auto;
}

.booking-choice .s5 {
  padding: 2px 30px;
  color: #377DFF;
  vertical-align: -webkit-baseline-middle;
  border-radius: 25px;
  margin-right: 20px;
}

.booking-choice .s5:hover, .booking-choice .s5:active {
  background-color: #377DFF;
  border-radius: 25px;
  color: #fff;
}

.booking-choice .s5 i {
  margin-right: 11px;
}

.traveller-block {
  padding: 20px;
  background-color: #fff;
}

.traveller-block .travel_alert {
  display: flex;
  background-color: #F8E187;
  color: #AB9122;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  font-size: 11px;
  border-left: 5px solid orange;
}

.traveller-block .travel_alert .warn-icon {
  color: #fff;
  margin-right: 5px;
}

.traveller-details {
  background-color: #fff;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
}

.traveller-details table tr td {
  font-size: 14px;
  padding: 15px;
}

.total-fare {
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
}

.total-fare tr td {
  border: 0;
}

.total-fare .upload-input.in-total-fare {
  border: 1px dashed #C4C4C4;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  background: #fff;
  position: relative;
  padding: 10px;
  text-align: center;
}

.total-fare .upload-input.in-total-fare:focus, .total-fare .upload-input.in-total-fare:hover, .total-fare .upload-input.in-total-fare:active {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px dashed #bfbfbf;
}

.total-fare .upload-input.in-total-fare input {
  position: absolute;
  top: 0px;
  right: auto;
  bottom: auto;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.table-forbooking .booking-options th {
  padding: 20px;
}

.table-forbooking .booking-type th:nth-child(2n) {
  background-image: linear-gradient(-90deg, #3E84FF, #579D);
  color: #fff;
  position: relative;
}

.table-forbooking .booking-type th:nth-child(2n)::after {
  content: "";
  position: absolute;
  top: 37px;
  left: 29px;
  border-top: 7px solid #3E84FF;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.table-forbooking .booking-type th:nth-child(2n+1) {
  background-image: linear-gradient(-90deg, #80008063, #8C82F5);
  color: #fff;
  position: relative;
}

.table-forbooking .booking-type th:nth-child(2n+1):first-child {
  background-image: linear-gradient(#fff, #fff);
  color: #334681;
}

.table-forbooking .booking-type th:nth-child(2n+1):first-child::after {
  content: none;
}

.table-forbooking .booking-type th:nth-child(2n+1)::after {
  content: "";
  position: absolute;
  top: 37px;
  left: 29px;
  border-top: 7px solid #80008063;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.table-forbooking .booking-type .last-text {
  font-size: 12px;
}

.table-forbooking .booking-type .last-text:nth-child(2n)::after {
  content: "";
  position: absolute;
  top: 37px;
  left: 56px;
  border-top: 7px solid #3E84FF;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.table-forbooking .booking-type .last-text:nth-child(2n+1)::after {
  content: "";
  position: absolute;
  top: 37px;
  left: 56px;
  border-top: 7px solid #80008063;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.table-forbooking tbody tr td {
  padding: 24px;
  font-weight: bold;
  color: #377DFF;
}

.table-forbooking tbody tr td:first-child {
  color: #8b8c99;
  background-color: #fff;
}

.table-forbooking tbody tr td:nth-child(2n) {
  background-color: #F0F7FF;
}

.table-forbooking tbody tr td:nth-child(2n+1) {
  background-color: #EFEDFC;
}

.table-forbooking tbody tr.cancel td {
  color: #f00;
}

.table-forbooking tbody tr.cancel td:first-child {
  color: #8b8c99;
}

.table-forbooking tbody tr.reschedule td {
  color: #80008063;
}

.table-forbooking tbody tr.reschedule td:first-child {
  color: #8b8c99;
}

.table-forbooking tbody tr.quality--set-pad td {
  padding: 0;
}

.table-forbooking tbody tr.quality--set-pad td .full-pad {
  display: block;
  padding: 21px;
}

.text-purple {
  color: #80008063;
}

.text-purple:hover {
  color: #80008063;
}

.w-70 {
  width: 70%;
}

.hotel-room-photos {
  width: 100%;
}

.hotel-room-photos .overflow {
  width: 100%;
  overflow: auto;
}

.border-radius-20 {
  border-radius: 30px;
}

.font-size-13 {
  font-size: 13px;
}

.opacity-7 {
  opacity: 0.7;
}

.h-100 {
  height: 100px;
}

.w-30 {
  width: 30% !important;
}

/* DayPickerInput CSS copied from deeptravel-fe*/
.form-group {
  position: relative;
  margin-bottom: 25px;
}

.form-group .DayPickerInput {
  padding: 5px 10px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  background-color: transparent;
  width: 100%;
  position: relative;
  border: 1px solid #ced4da;
  text-align: center;
}

.form-group .DayPickerInput input {
  border: 0;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  height: 100%;
  padding: 0;
  background-color: transparent;
  width: 100%;
  outline: 0;
  color: #8b8c99;
}

.form-group .DayPickerInput input::-webkit-input-placeholder {
  color: #8b8c99;
  opacity: 1;
}

.form-group .DayPickerInput input::-ms-input-placeholder {
  color: #8b8c99;
  opacity: 1;
}

.form-group .DayPickerInput input::placeholder {
  color: #8b8c99;
  opacity: 1;
}

.form-group .DayPickerInput input:focus, .form-group .DayPickerInput input:active, .form-group .DayPickerInput input:hover {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  outline: 0;
}

.form-group .DayPickerInput .DayPickerInput-OverlayWrapper {
  z-index: 999999;
}

.form-group.custom {
  border: 1px solid #C4C4C4;
  height: 40px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: #fff;
}

.form-group.custom.white {
  background: #fff;
}

.form-group.custom label {
  position: absolute;
  top: 9px;
  right: auto;
  bottom: auto;
  left: 10px;
  background: transparent;
  padding: 0 4px;
  transition: all 0.1s ease-in;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -o-border-radius: 25px;
  z-index: 0;
}

.form-group.custom .form-control {
  position: relative;
  border: 0px;
  padding: 0 15px;
  line-height: 40px;
  height: 40px;
  outline: 0;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -o-border-radius: 0px;
  background-color: transparent;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  z-index: 1;
}

.form-group.custom .form-control:-moz-read-only, .form-group.custom .form-control[readonly] {
  cursor: text;
  opacity: 0.7;
  background-color: #F7F7F7;
  height: 99%;
}

.form-group.custom .form-control:-moz-read-only + label, .form-group.custom .form-control[readonly] + label {
  top: -10px;
  z-index: 1;
  background-color: #fff;
}

.form-group.custom .form-control:focus, .form-group.custom .form-control:active, .form-group.custom .form-control:hover {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  outline: 0;
}

.form-group.custom .form-control:not(:placeholder-shown) + label {
  top: -10px;
  z-index: 1;
  background-color: #fff;
}

.form-group.custom .form-control:focus + label {
  top: -10px;
  z-index: 1;
  background-color: #fff;
}

.form-group.custom .select__control {
  border: 0px;
}

.form-group.custom .select__control.select__control--is-focused {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.form-group.custom .DayPickerInput {
  padding: 0;
  height: 100%;
  background-color: transparent;
  width: 100%;
  position: relative;
  border: 0;
  z-index: 10;
}

.form-group.custom .DayPickerInput input {
  border: 0;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  height: 100%;
  padding: 0 15px;
  background-color: transparent;
  width: 100%;
}

.form-group.custom .abs {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 20px;
}

.form-group.custom.percen {
  padding-left: 38px;
}

.form-group.custom.percen .abs-p {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 38px;
  background-color: #377DFF;
  color: #fff;
  text-align: center;
  line-height: 38px;
}

.form-group.custom .dropdown {
  height: 100%;
  width: 100%;
}

.form-group.custom .dropdown button {
  border: 0;
  height: 100%;
  width: 100%;
  line-height: 40px;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.form-group.custom .dropdown button:hover, .form-group.custom .dropdown button:active, .form-group.custom .dropdown button:focus {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.form-group.custom .dropdown:focus, .form-group.custom .dropdown:hover, .form-group.custom .dropdown:active {
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
}

.form-group.custom.labelup label {
  top: -10px;
  z-index: 1;
  background-color: #fff;
  white-space: nowrap;
}

.form-group.custom.textarea {
  height: auto;
}

.form-group.custom.textarea textarea.form-control {
  line-height: 1.5em;
  min-height: 150px;
  padding-top: 10px;
  height: auto;
}

.form-group.has-error {
  position: relative;
}

.form-group .help-block {
  position: absolute;
  top: 100%;
  right: auto;
  bottom: auto;
  left: 0px;
  color: #ff7979;
  line-height: 1rem;
  margin-top: 2px;
  font-size: 12px;
}

.form-group .help-block.from-top {
  top: 86%;
  bottom: auto;
}

.check-list-opts {
  padding: 25px;
}

.modal-content {
  border: 1px solid #f1f1f2;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.modal-content .fa-file {
  font-size: 50px;
}

.modal-content .modal-px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.modal-content .modal-px-ly {
  padding-left: 30px;
}

.modal-content .modal-head {
  background-color: #377DFF;
  padding: 15px 30px;
  color: #fff;
}

.modal-content .modal-head .modal-subtitle {
  margin-bottom: 2px;
  opacity: 0.55;
  text-transform: uppercase;
}

.modal-content .modal-middle .heading,
.modal-content .modal-middle .total-amt {
  background-color: #E5F1FF;
}

.modal-content .modal-middle .heading p,
.modal-content .modal-middle .total-amt p {
  margin: 0;
  padding: 15px;
}

.modal-content .modal-last {
  text-align: right;
}

.modal-content .modal-last .btn-badge {
  padding: 6px 10px;
  line-height: 1.5;
}

.modal-content .modal-last .icon-margin {
  margin-left: 14px;
}

.modal-content .travel_alert {
  background-color: #feca57;
  display: inline-block;
}

.modal-content .travel_alert p {
  color: #fff;
  opacity: 1;
  margin-bottom: 0;
}

.modal-content .travel_alert .alert-icon {
  width: 4%;
  vertical-align: top;
}

.modal-content .travel_alert .alert-detail {
  width: 96%;
  vertical-align: top;
}

.muted-p {
  opacity: 0.60;
  margin-bottom: 10px;
}

.m-15 {
  margin: 15px;
}

.expense-right .dropdown {
  height: auto;
  vertical-align: middle;
}

.expense-right .dropdown .allocate-btn {
  background-color: #95afc0;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #95afc0;
  width: auto;
  padding-right: 30px;
  vertical-align: middle;
}

.table-field {
  margin-left: 30px;
  border-radius: 2px;
  border: 1px solid #C4C4C4;
  margin-top: -4px;
}

.table-field:focus {
  outline: 0;
}

.fare-validate .collapse-icon {
  width: 24px;
  text-align: center;
  height: 24px;
  line-height: 24px;
}

.fare-validate div[aria-expanded="true"] .add-modal-minus {
  display: block;
}

.fare-validate div[aria-expanded="true"] .add-modal-plus {
  display: none;
}

.fare-validate div.collapsed .add-modal-minus {
  display: none;
}

.fare-validate div.collapsed .add-modal-plus {
  display: block;
}

.rounded-add-btn {
  width: 40px;
  height: 40px;
  line-height: 20px;
}

td .w-85 {
  width: 85%;
}

.cancel-block .cancel_alert {
  font-size: 12px;
  display: flex;
  background-color: #F8E187;
  color: #AB9122;
  padding: 10px;
  border-left: 5px solid orange;
}

.cancel-block .warn-icon {
  color: #fff;
  margin-right: 5px;
}

.vender-table tr td {
  padding-left: 30px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.lighter-p {
  opacity: 0.6;
}

.pm-30 {
  padding: 30px;
}

.pm-15 {
  padding: 15px;
}

.minus-mr {
  margin-right: -15px;
}

.minus-ml {
  margin-left: -15px;
}

.checkbox-padding {
  padding-top: 3px;
}

.info-box {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.white-card {
  background-color: #fff;
  margin-bottom: 20px;
}

.white-card p {
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.country-info {
  height: 100vh;
  overflow-y: scroll;
}

.country__table th,
.country__table td {
  padding-left: 20px;
  padding-right: 20px;
  vertical-align: middle;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.country-info {
  height: 100vh;
  overflow-y: scroll;
}

.country__table th,
.country__table td {
  padding-left: 20px;
  padding-right: 20px;
  vertical-align: middle;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.template-output {
  padding: 15px;
  word-break: break-all;
  background-color: #ecf0f1;
}

td {
  vertical-align: middle !important;
}

.center-heading h4 {
  color: #334681;
  margin-bottom: 8px;
}

.center-heading .report_logo {
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
}

.center-heading .report_logo img {
  width: 100px;
  height: auto;
}

.report__box {
  padding: 12px 20px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #334681;
}

.report__box span {
  margin-left: 10px;
  font-size: 18px;
}

.report__box .align-sub {
  vertical-align: sub;
}

.report__box a {
  color: #334681;
}

.report__box a:hover {
  color: #334681;
}

.create-report .heading h5 {
  color: #334681;
}

.create-report .search-custom .form-control {
  padding: 20px;
  height: 100%;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -o-border-radius: 20px;
  box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -webkit-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  position: relative;
  border: 1px solid #f1f1f2;
  padding-left: 46px;
}

.create-report .search-custom {
  box-shadow: 0 0 0;
}

.create-report .search-custom span {
  z-index: 1;
  top: 8px;
  left: 20px;
  right: auto;
  bottom: auto;
}

.create-report .report_radio .radio-container {
  display: inline-block;
  margin-left: 36px;
  padding-left: 34px;
}

.create-report .card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 0;
  display: block;
  padding: 15px;
  margin-bottom: 15px;
  height: 400px;
  overflow: auto;
}

.create-report .card .heading {
  color: #334681;
}

.create-report .card li {
  margin-bottom: 10px;
}

.create-report .card .checkbox-container .checkmark {
  top: -2px;
}

.text-black {
  color: #334681;
}

.report__head {
  color: #334681;
  padding-left: 20px;
  font-size: 18px;
}

.icon-custom {
  position: absolute;
  top: 5px;
  right: 10px;
  bottom: auto;
  left: auto;
  color: #C4C4C4;
  cursor: pointer;
}

.expense-tabs li {
  float: left;
}

.expense-tabs li a {
  display: block;
  padding: 10px 35px;
  background-color: #eff6ff;
  border: 1px solid #f1f1f2;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  color: #8b8c99;
}

.expense-tabs li.active a {
  background-color: #fff;
  border: 1px solid #d6dbdf;
  border-bottom: 0;
  box-shadow: 0 1px 0 white;
  -moz-box-shadow: 0 1px 0 white;
  -webkit-box-shadow: 0 1px 0 white;
  -o-box-shadow: 0 1px 0 white;
  color: #377DFF;
}

.expense-tabs::after {
  content: "";
  clear: both;
  display: block;
}

.company_img {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.company_img img {
  width: 100%;
  height: auto;
}

.mb-25 {
  margin-bottom: 25px;
  height: 39px;
  padding: 0 12px;
}

.adjust-dropdown {
  width: 40%;
}

.adjust-dropdown .dropdown {
  width: 100%;
}

.adjust-dropdown .dropdown button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 30px 0 15px;
}

.fixed-pop {
  position: fixed;
  top: 0;
  width: 40%;
  right: 0;
  z-index: 100000;
  height: 100vh;
  transition: all 0.3s ease-in;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
}

.fixed-pop .inner-div {
  height: 100%;
  overflow-y: scroll;
  position: relative;
}

.fixed-pop .inner-div h5 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99999999999;
}

.fixed-pop .inner-div .right {
  float: right;
  line-height: 1;
  cursor: pointer;
}

.fixed-pop .half {
  width: 49%;
  display: inline-block;
}

.fixed-pop .facilities {
  color: #377DFF;
  margin-bottom: 15px;
}

.fixed-pop .rounded-option {
  background-color: #F7F7F7;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  -o-border-radius: 25px;
  margin-left: 20px;
}

.hotel_info_head {
  background-color: #377DFF;
  padding: 15px;
  font-size: 17px;
}

.hotel_info_head .right {
  line-height: 0.7;
}

.fixed__bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
}

.auth__details {
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1);
}

.auth__details h6 {
  border: 1px solid #f1f1f2;
}

.auth__details .right {
  margin-left: 50px;
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

.resp-toggle-menu,
.admin-resp-show,
.resp-picon {
  display: none;
}

@media screen and (max-width: 1024px) {
  .sidebar {
    left: -63px;
  }
  .wrapper {
    margin: 0;
  }
  .inner-wrapper {
    margin-left: 50px;
  }
  .page-header {
    width: 100%;
    padding: 0px 16px 0 50px;
  }
  .page-header .resp-toggle-menu {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 49px;
    height: 49px;
    text-align: center;
    cursor: pointer;
  }
  .page-header .resp-toggle-menu i {
    line-height: 49px;
  }
}
/*# sourceMappingURL=main.css.map */
